const axios = require('axios');

const getUtcTimestamp = () => {
  const now = new Date();
 
  return Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
    now.getUTCMilliseconds(),
  );
};

let ALGOLIA_EVENTS_URL;
const sendEventToApi = (body) => {
  axios.post(ALGOLIA_EVENTS_URL, body);
};

function initAlgoliaEventsUrl(url) {
  ALGOLIA_EVENTS_URL = url;
}

function sendViewedObjectsId(payload) {
  if (!payload.index) return;
  const maxObjectsInEvent = 20;
  const eventsBody = [];

  while (payload.objectIDs?.length) {
    eventsBody.push({
      eventType: 'view',
      eventName: 'Products Viewed',
      index: payload.index,
      objectIDs: payload.objectIDs.splice(0, maxObjectsInEvent),
      userToken: payload.userToken,
      authenticatedUserToken: payload.authenticatedUserToken,
      timestamp: getUtcTimestamp()
    })
  }
  
  const body = { events: eventsBody };
  sendEventToApi(body);
}

function sendViewedFilters(payload) {
  if (!payload.index) return;
  const maxFiltersInEvent = 10;
  const eventsBody = [];
  
  while (payload.filters?.length) {
    eventsBody.push({
      eventType: 'view',
      eventName: 'Viewed Filters',
      index: payload.index,
      filters: payload.filters.splice(0, maxFiltersInEvent),
      userToken: payload.userToken,
      authenticatedUserToken: payload.authenticatedUserToken,
      timestamp: getUtcTimestamp()
    });
  }

  const body = { events: eventsBody };
  sendEventToApi(body);
}

function sendClickedObjectIDsAfterSearch(payload) {
  if (!payload?.queryID || !payload.index) return;

  const body = {
    events: [
      {
        eventType: 'click',
        eventName: 'Products Clicked After Search',
        index: payload.index,
        queryID: payload.queryID,
        objectIDs: payload.objectIDs,
        positions: payload.positions,
        userToken: payload.userToken,
        authenticatedUserToken: payload.authenticatedUserToken,
        timestamp: getUtcTimestamp()
      },
    ],
  };
  sendEventToApi(body);
}

function sendAddedToCartObjectIDsAfterSearch(payload) {
  if (!payload?.queryID || !payload.index) return;

  const body = {
    events: [
      {
        eventType: 'conversion',
        eventSubtype: 'addToCart',
        eventName: 'Products Added to Cart After Search',
        index: payload.index,
        queryID: payload.queryID,
        objectIDs: payload.objectIDs,
        objectData: payload.objectData,
        value: payload.value,
        currency: payload.currency,
        userToken: payload.userToken,
        authenticatedUserToken: payload.authenticatedUserToken,
        timestamp: getUtcTimestamp()
      },
    ],
  };
  sendEventToApi(body);
}

function sendClickedFilters(payload) {
  if (!payload?.index) return;

  const body = {
    events: [
      {
        eventType: 'click',
        eventName:"Clicked Filters",
        index: payload.index,
        filters: payload.filters,
        userToken: payload.userToken,
        authenticatedUserToken: payload.authenticatedUserToken,
        timestamp: getUtcTimestamp()
      },
    ],
  };
  sendEventToApi(body);
}

function sendConvertedFilters(payload) {
  if (!payload?.index) return;

  const body = {
    events: [
      {
        eventType: 'conversion',
        eventName: 'Filters Converted',
        index: payload.index,
        filters: payload.filters,
        userToken: payload.userToken,
        authenticatedUserToken: payload.authenticatedUserToken,
        timestamp: getUtcTimestamp()
      },
    ],
  };
  sendEventToApi(body);
}

function sendPurchaseAfterSearch(payload) {
  const body = {
    events: [
      {
        eventType: 'conversion',
        eventSubtype: 'purchase',
        eventName: 'Products Purchased After Search',
        index: payload.index,
        queryID: payload.queryID,
        objectIDs: payload.objectIDs,
        objectData: payload.objectData,
        value: payload.value,
        currency: payload.currency,
        userToken: payload.userToken,
        authenticatedUserToken: payload.authenticatedUserToken,
        timestamp: getUtcTimestamp()
      },
    ],
  };
  sendEventToApi(body);
}

function sendConvertedObjectIdsAfterSearch(payload) {
  if (!payload?.queryID || !payload.index) return;

  const body = {
    events: [
      {
        eventType: 'conversion',
        eventName: 'Items Converted After Search',
        index: payload.index,
        queryID: payload.queryID,
        objectIDs: payload.objectIDs,
        userToken: payload.userToken,
        authenticatedUserToken: payload.authenticatedUserToken,
        timestamp: getUtcTimestamp()
      },
    ],
  };
  sendEventToApi(body);
}

function setProductDataLayerData(datalayer, query_id, index_name, objectId) {
  datalayer.search_engine = {
    identifier: 'algolia',
    field: {
      query: query_id,
    },
    indexable_name: index_name,
    object_id: objectId,
  };

  return datalayer;
}

module.exports = {
  sendViewedObjectsId,
  sendViewedFilters,
  sendClickedObjectIDsAfterSearch,
  sendAddedToCartObjectIDsAfterSearch,
  sendClickedFilters,
  sendConvertedFilters,
  sendPurchaseAfterSearch,
  sendConvertedObjectIdsAfterSearch,
  initAlgoliaEventsUrl,
  setProductDataLayerData,
};
